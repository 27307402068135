<template>
    <div :id="id" :class="className" :style="{ height: height, width: width }" />
</template>

<script>
import * as echarts from 'echarts'
import resize from './mixins/resize'

export default {
    mixins: [resize],
    props: {
        chartData: {
            type: Object,
            default: function () {
                return {}
            }
        },
        chartName: {
            type: String,
            default: ''
        },
        className: {
            type: String,
            default: 'chart'
        },
        yShow: {
            type: Boolean,
            default: true
        },
        id: {
            type: String,
            default: 'chart'
        },
        width: {
            type: String,
            default: '200px'
        },
        height: {
            type: String,
            default: '200px'
        }
    },
    data() {
        return {
            chart: null
        }
    },
    mounted() {
        this.initChart()
    },
    beforeDestroy() {
        if (!this.chart) {
            return
        }
        this.chart.dispose()
        this.chart = null
    },
    methods: {
        initChart() {
            this.chart = echarts.init(document.getElementById(this.id))
            this.chart.setOption({
                tooltip: {
                    trigger: 'axis'
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: this.chartData.xData
                },
                yAxis: {
                    show: this.yShow,
                    type: 'value'
                },
                grid: {
                    top: '8%',
                    left: '8%',
                    right: '8%',
                    bottom: '15%'
                },
                series: {
                    name: this.chartName,
                    type: 'line',
                    areaStyle: {},
                    showSymbol: false,
                    data: this.chartData.sData
                }
            })
        }
    }
}
</script>
